$(document).ready(function () {
    var modalForm;

    $(document).on('click', '.js-take-appointment', function () {
        modalForm = $('.form-back-appointment').modal('show');

        modalForm.one('hidden.bs.modal', function () {
            modalForm.find('input:not(:radio):not(:checkbox)').val('');
        });

        return false;
    });

    var form = $('.form-back-appointment form'),
        spinnerContainer = form.find('.modal-content');

    __form_ajax(form, spinnerContainer, function () {
        var modal = $('.success-call-back').modal('show');
        modal.on('hidden.bs.modal', function () {
            modalForm.modal('hide');
        });
    }, 'form-back-appointment_');
});
